/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import type { LegacyRef, MouseEventHandler, ReactNode } from 'react';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@compiled/react';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import { DropdownItem, type DropdownItemProps } from '@atlaskit/dropdown-menu';
import Badge from '@atlaskit/badge';
import { SpotlightPulse, SpotlightTransition } from '@atlaskit/onboarding';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
});

const labelContainerStyles = xcss({
	flex: 2,
});

const badgeContainerStyles = xcss({
	paddingLeft: 'space.075',
});

class ContentToolsItemClass extends PureComponent<ContentToolsItemClassProps> {
	static propTypes = {
		onClick: PropTypes.func,
		className: PropTypes.string,
		id: PropTypes.string.isRequired,
		testId: PropTypes.string,
		url: PropTypes.string,
		label: PropTypes.node.isRequired,
		isDisabled: PropTypes.bool,
		innerRef: PropTypes.object,
		linkComponent: PropTypes.elementType,
		onMouseEnter: PropTypes.func,
		shouldHighlight: PropTypes.bool,
		elemBefore: PropTypes.elementType,
	};

	static defaultProps = {
		onMouseEnter: () => {},
	};

	render() {
		const {
			label,
			onClick,
			url,
			id,
			className,
			isDisabled,
			innerRef,
			testId,
			onMouseEnter,
			shouldHighlight,
			elemBefore,
			interactionName,
		} = this.props;

		const contentToolsItem = (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div onMouseEnter={onMouseEnter} data-test-id={id}>
				<DropdownItem
					// @ts-ignore DropdownItem doesn't declare support for className
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={className}
					id={id}
					href={url ?? undefined}
					testId={(testId || id) ?? undefined}
					onClick={onClick}
					isDisabled={isDisabled}
					elemBefore={elemBefore}
					UNSAFE_shouldDisableRouterLink
					interactionName={interactionName}
				>
					{typeof label === 'string' ? (
						<Container>
							<Flex gap="space.075" alignItems="center" xcss={labelContainerStyles}>
								<span
									ref={innerRef}
									data-test-id="content-item-label"
									// webitems sometimes have html (for keyboard shortcuts)
									dangerouslySetInnerHTML={{
										__html: label.replace(/[0-9]/g, '').replace(/[{()}]/g, ''),
									}}
								/>
							</Flex>
							{label.replace(/\D/g, '').length > 0 ? (
								<Box xcss={badgeContainerStyles}>
									<Badge>{label.replace(/\D/g, '')}</Badge>
								</Box>
							) : null}
						</Container>
					) : (
						label
					)}
				</DropdownItem>
			</div>
		);

		if (shouldHighlight) {
			return (
				<Box paddingInline="space.025">
					<SpotlightTransition>
						<SpotlightPulse radius={4}>{contentToolsItem}</SpotlightPulse>
					</SpotlightTransition>
				</Box>
			);
		}

		return contentToolsItem;
	}
}

export type ContentToolsItemClassProps = {
	id: string | null;
	innerRef: LegacyRef<HTMLElement>;
	label: ReactNode;
	onMouseEnter?: MouseEventHandler;

	// Pass-through props for DropdownItem:
	className?: string;
	url?: DropdownItemProps['href'] | null;
	shouldHighlight?: boolean;
	elemBefore?: DropdownItemProps['elemBefore'];
} & Pick<DropdownItemProps, 'isDisabled' | 'onClick' | 'testId' | 'interactionName'>;

export const ContentToolsItem = React.forwardRef(
	(
		props: Omit<ContentToolsItemClassProps, 'innerRef'>,
		ref: ContentToolsItemClassProps['innerRef'],
	) => <ContentToolsItemClass innerRef={ref} {...props} />,
);
ContentToolsItem.displayName = 'ContentToolsItem';
